.sidebar {
  width: 100%;
  background-color: #fff;
  height: 100vh;
  padding: 24px 0;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);

  .main-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: calc(100vh - 80px);
  }
  .logo {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    display: grid;
    place-content: center;
  }

  nav {
    ul {
      list-style: none;
      padding: 0;

      li {
        margin-bottom: 10px;

        a {
          text-decoration: none;
          color: #333;
          display: block;
          padding: 10px;
          border-radius: 5px;

          &:hover {
            background-color: #f0f0f0;
          }
        }

        &.dropdown {
          ul {
            display: none;
            list-style: none;
            padding-left: 20px;

            li {
              margin-bottom: 0;

              a {
                padding: 5px 10px;

                &:hover {
                  background-color: #f0f0f0;
                }
              }
            }
          }

          &:hover ul {
            display: block;
          }
        }
      }
    }
  }

  .settings {
    margin-top: 20px;

    ul {
      list-style: none;
      padding: 0;

      li {
        a {
          text-decoration: none;
          color: #333;
          display: block;
          padding: 10px;
          border-radius: 5px;

          &:hover {
            background-color: #f0f0f0;
          }
        }
      }
    }
  }

  .profile {
    margin-top: 20px;
    display: flex;
    align-items: center;

    img {
      border-radius: 50%;
      width: 50px;
      height: 50px;
    }

    span {
      display: block;
    }

    .role {
      font-size: 12px;
      color: #999;
    }
  }

  .help-logout {
    margin-top: 20px;
    text-align: center;

    a {
      text-decoration: none;
      color: #333;
      display: block;
      padding: 10px;
      border-radius: 5px;

      &:hover {
        background-color: #f0f0f0;
      }
    }
  }
}
