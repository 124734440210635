.leads {
  &-header {
    h1 {
      font-weight: 600;
      font-size: 16px;
    }
    padding: 25px 31px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
