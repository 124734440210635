/* src/styles/tailwind.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --border-default: #DDDEE1;
}

.defaultViewTable > div:nth-child(2){
    min-height: calc(100vh - 78px) !important;
}