.topbar{
    background-color: #fff;
    padding: 20px 31px;

    .search{
        width: 100%;
        max-width: 388px;
        
        
    }

}